body,html{
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    background-color: #fff;
    font-family: 'Roboto', 'Arial', 'Helvetica', 'sans-serif';
}
.container{
    padding-top: 60px;
}
.notificationBar{
    padding:15px 10px;
    margin: 30px 0px 40px 0px;
    text-align: left; 
    border-radius: 5px;
    background-color: #51d9ee;
}
.Recharge-card-box{
    display: none;
    flex-direction: row;
    justify-content: flex-start;
}
 
.Dash-Flex-CardBox-1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Admin-Dash-Flex-CardBox-2{
    display: block;
    width: 100%;
}
.Dash-sub-heading{
    padding: 15px 0px;
    text-align: left;
    font-size: 20px;
    font-weight:500;
    margin-top: 50px;
    
}

.hidden{
    display: none;
    width: 0px;
}

.bg-full-blank{
    left: 0;
    top: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    
    z-index: 1000000;

}



.drawer{
    position: fixed;
    z-index: 10000001;
    height: 100vh;
    background-color: #fff;

    transition: margin 0.2s;
    width: 70%;
    margin: 0 0 0 -70%;
}

.drawer.isOpen{
    margin: 0%;
    box-shadow: 0 0 8px #00000069;

}

.heading{
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    margin: 15px 0px;
}
.dash-report-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    margin:10px;
    border-radius: 10px;
   box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}

.text-success{
    color: #007bff !important;
}

.text-pending{
    color: #ffc107 !important;
}

.text-failure{
    color: #dc3545 !important;
}

.bg-success{
    background-color: #007bff !important;
    color: #fff;
}

.bg-failure{
    background-color: #dc3545 !important;
    color: #fff;
}

.bg-pending{
    background-color: #ffc107 !important;
    color: #333;
}

.bg-info{
    background-color: #17a2b8 !important;
    color: #fff;
}

.Titleheading{
    padding: 20px 30px 10px 30px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;

}
.SubtitleBox{
    padding: 10px 30px 20px 30px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
}
.Dash-recent-transection{
    flex-grow: 1;
    display: flex;
   width: 100%;
    overflow: hidden;
    overflow-x: auto;
    flex-direction:column;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2)
}


.iconButton{
    display: inline-block;
    border-radius: 100%;
    height: 24px;
    width: 24px;
    padding: 24px;
    background-color: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
    cursor: pointer;
}

tr {
    border-bottom: 1px solid #dcdcdc;
}

tr:hover {
    background-color:  #dcdcdc;
}

.Dash-rech-table-view{
    border-collapse: collapse;
}
tr{
    padding: 20px 0px;
}
th{
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border-bottom: 2px solid #dcdcdc;
}
td{
    padding: 15px 20px;
    text-align: center;
}



.AgentsDetailsTilesBox{
    display: flex;
    flex-direction: column;
    padding-top:10px;
    padding-bottom:30px;
    border-radius: 10px;
    width:100%;
    max-width: 300px;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.Agent-profile-box{
    padding-top: 10px;
    text-align: center;
}
.TilesColumns{
    padding: 10px 30px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}
.Pass-btn, .setting-btn{
    width: 160px;
    margin: 10px auto;
    border-radius: 5px;
    padding: 10px 20px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}

@media only screen and (max-width: 800px){
    .Recharge-card-box{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    .Dash-Flex-CardBox-1{
        flex-direction: column;
    }
    .dash-report-card{
        margin: 0px 0px 20px 0px;
        max-width: 100%;
    }
    .AgentsDetailsTilesBox{
        margin: 30px 0px;
        max-width: unset;
    }
   
    .notificationBar{
        margin: 30px 0px 30px 0px;
    }
    .Dash-sub-heading{
        margin-top: 30px;
        
    }
}
@media only screen and (max-width: 600px){
    .Recharge-card-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}