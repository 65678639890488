.Radio-group {
    display: flex;
    flex-direction: row;
    text-align: start;
    padding: 20px 0px;
    flex-grow: 1;
    min-width: 160px;
    width: 100%;
    max-width: 332px;
}

.radio-box {
    padding: 10px 0px;
}

.input-form-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Mob-form-control {
    padding: 10px 16px;
    border-radius: 5px;
    flex-grow: 1;
    min-width: 160px;
    margin: 10px 0px;
    width: 100%;
    max-width: 300px;
    outline: none;
    border: 1px solid #ccc;
    color: #333;
}

select.Mob-form-control {
    max-width: 332px;
}

.rechargeConfirmTile, .confirmTile {
    padding: 5px 0px;
}

.rechargeHeadingTextCol, .dmtHeadingTextCol {
    font-size: 18px;
    font-weight: 500;
}

.confirmWrap {
    padding: 2rem;
}

.mpin {
    border: 1px solid #ddd;
    outline: none;
    margin-top: 2px;
    padding: 10px;
}

.statusCard {
    width: 120px;
    height: 120px;
    border-radius: 120px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: auto;
}

.statusCard i {
    font-size: 60px;
    color: #fff;
}

@media only screen and (max-width: 600px) {
    .confirmWrap {
        padding: 1rem;
    }
    .mpin {
        display: block;
        width: calc(100% - 20px);
    }
    .rechargeConfirmTile, .rechargeConfirmTile div, .rechargeConfirmTile div button {
        display: block;
        width: 100%;
    }
    .confirmTile, .confirmTile div, .rechargeConfirmTile div button{
        display: block;
        width: 100%;
    }
}