.Payment-request-box{
    margin-top:50px;
}

.content-wrap{
    width: 96%;
    margin: auto;
    display: block;
}

.paddinglr-20{
    padding: 20px;
}
.Payment-request-card{
    display: block;
    width: 100%;
    justify-content: center;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
    border-radius: 10px;

}

.Payment-request-form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.form-group{
    text-align: start;
    flex-grow: 1;
    min-width: 160px;
    margin: 0px 10px;
    max-width: 332px;
    justify-content: start;
}


.form-control{
    padding: 10px 16px;
    border-radius: 5px;
    flex-grow: 1;
    min-width: 160px;
    margin: 10px;
    width: 100%;
    max-width: 300px;
    outline: none;
    border:1px solid #ccc;
    color:#333;
}




select.form-control {
    max-width: 332px;
}


.Account-detail-box, .Payment-Request-detail-box{
    margin-top: 50px;
}
.Account-detail-card, .Payment-Request-detail-card{
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    overflow: auto;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.Account-detail-table, .Payment-Request-detail-table{
    width: 100%;
    border-collapse: collapse;
   
}

@media only screen and (max-width: 800px){

    .form-control{
        padding: 10px 16px;
        border-radius: 5px;
        flex-grow: 1;
        min-width: 160px;
        margin: 10px;
        width: 100%;
        max-width: unset;
        outline: none;
        border:1px solid #ccc;
        color:#333;
    }
    
    
    select.form-control {
        max-width: unset;
    }
    .content-wrap{
        width:92%;
    }



}