.Container {
  display: flex;
  justify-content: center;
}
.Box {
  margin-top: 200px;
  padding: 50px 0px;
  width: 350px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.2);
}

.link {
  display: block;
  text-align: center;
  text-decoration: none;
  margin-top: 20px;
}

.errorClass {
  color: red;
  padding: 10px;
}

.logoBox {
  display: flex;
  justify-content: center;
}
.LogoImage {
  font-size: 50px;
  color: #680e7a;
  border-radius: 100%;
}
.FormWrap {
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
}
.InputText {
  padding: 10px 16px;
  border-radius: 5px;
  margin-bottom: 10px;
  outline: none;
  border: 1px solid #ccc;
  color: #333;
}
.SubmitBtn {
  padding: 10px 5px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  background-color: #680e7a;
  color: #fff;
}
