body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Page404Card{
  padding: 4rem 0px;
  text-align: center;
}
.circularBorder{
  border: 1px solid #ccc;
  margin: 0px 10px;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
}
.circularBorder:hover{
  background-color: #ddd;
}
.Page404Heading{
  font-size: 100px;
  font-weight: 800;
  text-align: center;
  padding: 1rem 0px;
  color:#650a65;
}
.Page404SubHeading{
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding: 10px 0px 2rem 0px;
  border-bottom: 1px solid #333;

}

.text-center{
  text-align: center !important;
}

.justify-center{
  justify-content: center;
}

.mt-1{

  margin-top: 1rem;

}
.mx-auto{
  margin-left: auto;
  margin-right: auto;
}
.mx-1{

  margin-right: 1rem;
  margin-left: 1rem;

}

.p-5{

  padding: 5rem;

}

.p-1{

  padding: 1rem;

}

.p-2{

  padding: 2rem;

}

.pl-2{

  padding-left: 2rem;

}
.pt-1{

  padding-top: 1rem;

}
.pb-1{

  padding-bottom: 1rem;

}
.pl-1{

  padding-left: 1rem;

}
.pt-2{

  padding-top: 2rem;

}
.px-1{

  padding-right: 1rem;
  padding-left: 1rem;

}

.py-1{

  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-2{

  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pr-2{

  padding-right: 2rem;

}

.pb-2{

  padding-bottom: 2rem;

}

.p-3{

  padding: 3rem;

}

.p-4{

  padding: 4rem;

}

.bg-white{

  background-color: #ffffff;

}

.change_password_card, .change_mpin_card, .wallet_card, .aeps_card{
    display: block;
    width: 100%;
    justify-content: center;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
    border-radius: 10px;
    padding: 10px 0px;
}
.change_password_form, .change_mpin_form{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.box-shadow-6{

  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);

}
.btnTransparent{
  padding: 12px 16px;
  border: 0px;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  border-radius: 6px;
}
.btnTransparent:active{
  outline: none;
}
.arrowBtn{
  padding: 3px 10px 0px 1px;
  border: 0px;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}
.arrowBtn:active{
  outline: none;
}

.btn{
  padding: 12px 16px;
  border: 0px;
  outline: none;
  cursor: pointer;
  margin: 10px 0px;
  border-radius: 6px;
}

.btn:active{
  outline: none;
}

.PickAtService{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 5px;

}
.btn-primary{
  background-color: royalblue;
  color: #fff !important;
}

.error{
  font-size: 14px;
  font-weight: 400;
  padding: 1em;
}
.error-color{
  color: red;
}
.subHeading{
  font-size: 16px;
  font-weight: 500;
}
.btn-danger{
  background-color: red;
  color: #fff;
}

.alert{
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #ddd;

}
.table-collapse{
  width: 100%;
  border-collapse: collapse;
}

.alert-default{
  background-color: #f9f9f9;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}