.utilityDetailsWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .textHeadingCol {
    font-size: 18px;
    font-weight: 500;
  }
.utility-services{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    flex-wrap: wrap;
}
.utility-services-form{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.form-group{
    text-align: start;
    flex-grow: 1;
    min-width: 160px;
    margin: 0px 10px;
    max-width: 332px;
    justify-content: start;
}


.form-control{
    padding: 10px 16px;
    border-radius: 5px;
    flex-grow: 1;
    min-width: 160px;
    margin: 10px;
    width: 100%;
    max-width: 300px;
    outline: none;
    border:1px solid #ccc;
    color:#333;
}

@media only screen and (max-width: 800px){

    .form-control{
        padding: 10px 16px;
        border-radius: 5px;
        flex-grow: 1;
        min-width: 160px;
        margin: 10px;
        width: 100%;
        max-width: unset;
        outline: none;
        border:1px solid #ccc;
        color:#333;
    }
    .utility-services{
        
        justify-content:flex-start;
      
    }
    
    select.form-control {
        max-width: unset;
    }
    .content-wrap{
        width:92%;
    }



}