.Complain-card{
    width: 100%;
    display: flex;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
    justify-content: center;
}
.Complain-search-card{
    display: block;
    width: 100%;
    justify-content: center;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
    border-radius: 10px;
}
.complaint-search-form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Complain-form{
    display: flex;
    flex-direction:column;
    justify-content: center;
    flex-wrap: wrap;
    padding:20px;
}
.ComplainInputText{
    width:100%;
    min-width: 160px;
    max-width: 300px;
    border-radius: 5px;
    padding: 10px 16px;
    margin:10px;
    outline: none;
    color:#333;
    border:1px solid #ccc
}
.Complain-table-card{
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    overflow: auto;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.Complain-table{
width: 100%;
  border-collapse: collapse;
}
@media only screen and (max-width: 600px){
    .ComplainInputText{
        width:100%;
        min-width: 160px;
        max-width: 250px;
        border-radius: 5px;
        padding: 10px 16px;
        margin:10px;
        outline: none;
        color:#333;
        border:1px solid #ccc
    }
}
