body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.Page404Card{
  padding: 4rem 0px;
  text-align: center;
}
.circularBorder{
  border: 1px solid #ccc;
  margin: 0px 10px;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
}
.circularBorder:hover{
  background-color: #ddd;
}
.Page404Heading{
  font-size: 100px;
  font-weight: 800;
  text-align: center;
  padding: 1rem 0px;
  color:#650a65;
}
.Page404SubHeading{
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  padding: 10px 0px 2rem 0px;
  border-bottom: 1px solid #333;

}

.text-center{
  text-align: center !important;
}

.justify-center{
  justify-content: center;
}

.mt-1{

  margin-top: 1rem;

}
.mx-auto{
  margin-left: auto;
  margin-right: auto;
}
.mx-1{

  margin-right: 1rem;
  margin-left: 1rem;

}

.p-5{

  padding: 5rem;

}

.p-1{

  padding: 1rem;

}

.p-2{

  padding: 2rem;

}

.pl-2{

  padding-left: 2rem;

}
.pt-1{

  padding-top: 1rem;

}
.pb-1{

  padding-bottom: 1rem;

}
.pl-1{

  padding-left: 1rem;

}
.pt-2{

  padding-top: 2rem;

}
.px-1{

  padding-right: 1rem;
  padding-left: 1rem;

}

.py-1{

  padding-top: 1rem;
  padding-bottom: 1rem;
}
.py-2{

  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pr-2{

  padding-right: 2rem;

}

.pb-2{

  padding-bottom: 2rem;

}

.p-3{

  padding: 3rem;

}

.p-4{

  padding: 4rem;

}

.bg-white{

  background-color: #ffffff;

}

.change_password_card, .change_mpin_card, .wallet_card, .aeps_card{
    display: block;
    width: 100%;
    justify-content: center;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
    border-radius: 10px;
    padding: 10px 0px;
}
.change_password_form, .change_mpin_form{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.box-shadow-6{

  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);

}
.btnTransparent{
  padding: 12px 16px;
  border: 0px;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  border-radius: 6px;
}
.btnTransparent:active{
  outline: none;
}
.arrowBtn{
  padding: 3px 10px 0px 1px;
  border: 0px;
  background-color: transparent;
  outline: none;
  cursor: pointer;
}
.arrowBtn:active{
  outline: none;
}

.btn{
  padding: 12px 16px;
  border: 0px;
  outline: none;
  cursor: pointer;
  margin: 10px 0px;
  border-radius: 6px;
}

.btn:active{
  outline: none;
}

.PickAtService{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 5px;

}
.btn-primary{
  background-color: royalblue;
  color: #fff !important;
}

.error{
  font-size: 14px;
  font-weight: 400;
  padding: 1em;
}
.error-color{
  color: red;
}
.subHeading{
  font-size: 16px;
  font-weight: 500;
}
.btn-danger{
  background-color: red;
  color: #fff;
}

.alert{
  padding: 16px;
  border-radius: 6px;
  border: 1px solid #ddd;

}
.table-collapse{
  width: 100%;
  border-collapse: collapse;
}

.alert-default{
  background-color: #f9f9f9;
}
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
    margin: 0;
}
.App {
  font-size: 14px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.Container {
  display: flex;
  justify-content: center;
}
.Box {
  margin-top: 200px;
  padding: 50px 0px;
  width: 350px;
  background-color: #fff;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.2);
}

.link {
  display: block;
  text-align: center;
  text-decoration: none;
  margin-top: 20px;
}

.errorClass {
  color: red;
  padding: 10px;
}

.logoBox {
  display: flex;
  justify-content: center;
}
.LogoImage {
  font-size: 50px;
  color: #680e7a;
  border-radius: 100%;
}
.FormWrap {
  display: flex;
  padding: 30px;
  flex-direction: column;
  justify-content: center;
}
.InputText {
  padding: 10px 16px;
  border-radius: 5px;
  margin-bottom: 10px;
  outline: none;
  border: 1px solid #ccc;
  color: #333;
}
.SubmitBtn {
  padding: 10px 5px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  background-color: #680e7a;
  color: #fff;
}

.Admin-wrap{
    width: 96%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0px auto;
}

.user-details-box{
    padding-top:20px;
    display: flex;
    flex-direction: column;
}
.user-info-box, .user-package-info-box{
    display: flex;
    padding: 2rem;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
}
.user-info-Details-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.user-info-card{
    width: 100%;
    max-width: 300px;
    min-width: 160px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.user-info-label{
    padding: 5px 0px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
}
.user-info-disable-input{
    padding: 10px 0px;
    flex-grow: 1;
    min-width: 160px;
    margin-bottom: 10px;
    max-width: 300px;
    font-size: 16px;
    font-weight: 500;
    color: #333;
}
.user-label{
    padding: 10px 0px;
    font-size: 15px;
    font-weight: 500;
    color: #333;
}
.user-create-card{
    width: 100%;
    max-width: 332px;
    min-width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.user-input-box{
    padding: 10px;
    flex-grow: 1;
    min-width: 160px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 310px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #333;
}
select.user-input-box {
    max-width: 332px;
    color:#757575;
}

.user-info-input-box{
    padding: 10px 0px;
    flex-grow: 1;
    min-width: 160px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 300px;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    border: 0;
    border-bottom: 1px solid #ccc;
    color: #333;
}
.fund-input-box{
    padding: 10px 0px;
    min-width: 160px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 300px;
    outline: none;
    border: 0;
    border-bottom: 1px solid #ccc;
    color: #333;
}
.users-details-card{
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    overflow: auto;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.users-details-table{
    width: 100%;
    border-collapse: collapse;
   
}
.AdminError{
    width: 100%;
    padding:10px 0px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #333;
}
.UsersNavBarMenu{
    display: flex;
    flex-direction: row;
    
}
.NavBarBtnWrap{
    display: flex;
    flex-direction: row;
}
.SearchInput{
    padding: 10px 16px;
    border: 1px solid #ccc;
    outline: none;
    margin: 10px 0px;
    border-radius: 6px;
  }
  .User-Details-wrap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.inputEditbox{
    outline: none;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin: 0px 10px;
    padding: 8px;
  
}

@media only screen and (max-width: 800px){
    .Admin-wrap{
        width: 92%;
    }
    .user-info-box{
        display: flex;
        padding: 2rem 10px;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
    }
    .user-info-card{
        width: 100%;
        max-width: 300px;
        min-width: 160px;
        margin: 0px auto;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .user-info-Details-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
}
@media only screen and (max-width: 600px){
    .UsersNavBarMenu{
        display: flex;
        flex-direction: column;
        
    }
    .user-create-card{
        width: 100%;
        max-width: 312px;
        min-width: 160px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .user-input-box{
        padding: 10px;
        flex-grow: 1;
        min-width: 160px;
        margin-bottom: 10px;
        width: 100%;
        max-width:290px;
        outline: none;
        border: 1px solid #ccc;
        border-radius: 5px;
        color: #333;
    }
    select.user-input-box {
        max-width: 312px;
        color:#757575;
    }
}
body,html{
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    background-color: #fff;
    font-family: 'Roboto', 'Arial', 'Helvetica', 'sans-serif';
}
.container{
    padding-top: 60px;
}
.notificationBar{
    padding:15px 10px;
    margin: 30px 0px 40px 0px;
    text-align: left; 
    border-radius: 5px;
    background-color: #51d9ee;
}
.Recharge-card-box{
    display: none;
    flex-direction: row;
    justify-content: flex-start;
}
 
.Dash-Flex-CardBox-1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Admin-Dash-Flex-CardBox-2{
    display: block;
    width: 100%;
}
.Dash-sub-heading{
    padding: 15px 0px;
    text-align: left;
    font-size: 20px;
    font-weight:500;
    margin-top: 50px;
    
}

.hidden{
    display: none;
    width: 0px;
}

.bg-full-blank{
    left: 0;
    top: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    
    z-index: 1000000;

}



.drawer{
    position: fixed;
    z-index: 10000001;
    height: 100vh;
    background-color: #fff;

    transition: margin 0.2s;
    width: 70%;
    margin: 0 0 0 -70%;
}

.drawer.isOpen{
    margin: 0%;
    box-shadow: 0 0 8px #00000069;

}

.heading{
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    margin: 15px 0px;
}
.dash-report-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    margin:10px;
    border-radius: 10px;
   box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}

.text-success{
    color: #007bff !important;
}

.text-pending{
    color: #ffc107 !important;
}

.text-failure{
    color: #dc3545 !important;
}

.bg-success{
    background-color: #007bff !important;
    color: #fff;
}

.bg-failure{
    background-color: #dc3545 !important;
    color: #fff;
}

.bg-pending{
    background-color: #ffc107 !important;
    color: #333;
}

.bg-info{
    background-color: #17a2b8 !important;
    color: #fff;
}

.Titleheading{
    padding: 20px 30px 10px 30px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;

}
.SubtitleBox{
    padding: 10px 30px 20px 30px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
}
.Dash-recent-transection{
    flex-grow: 1;
    display: flex;
   width: 100%;
    overflow: hidden;
    overflow-x: auto;
    flex-direction:column;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2)
}


.iconButton{
    display: inline-block;
    border-radius: 100%;
    height: 24px;
    width: 24px;
    padding: 24px;
    background-color: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
    cursor: pointer;
}

tr {
    border-bottom: 1px solid #dcdcdc;
}

tr:hover {
    background-color:  #dcdcdc;
}

.Dash-rech-table-view{
    border-collapse: collapse;
}
tr{
    padding: 20px 0px;
}
th{
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border-bottom: 2px solid #dcdcdc;
}
td{
    padding: 15px 20px;
    text-align: center;
}



.AgentsDetailsTilesBox{
    display: flex;
    flex-direction: column;
    padding-top:10px;
    padding-bottom:30px;
    border-radius: 10px;
    width:100%;
    max-width: 300px;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.Agent-profile-box{
    padding-top: 10px;
    text-align: center;
}
.TilesColumns{
    padding: 10px 30px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}
.Pass-btn, .setting-btn{
    width: 160px;
    margin: 10px auto;
    border-radius: 5px;
    padding: 10px 20px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}

@media only screen and (max-width: 800px){
    .Recharge-card-box{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    .Dash-Flex-CardBox-1{
        flex-direction: column;
    }
    .dash-report-card{
        margin: 0px 0px 20px 0px;
        max-width: 100%;
    }
    .AgentsDetailsTilesBox{
        margin: 30px 0px;
        max-width: unset;
    }
   
    .notificationBar{
        margin: 30px 0px 30px 0px;
    }
    .Dash-sub-heading{
        margin-top: 30px;
        
    }
}
@media only screen and (max-width: 600px){
    .Recharge-card-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
.packageNameList{
    width: 250px;
    display: flex;
    flex-direction: column;
}
.packageBox {
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    padding: 10px 0px 10px 1rem ;
}
.PackageStatus{
    font-size: 15px;
    font-weight: 500;
    padding: 12px 16px;
    margin: 10px 0px;
}
.PackageDetails{
    width: calc(100% - 250px);
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    border-left: 1px solid #ddd;
}
.packageNavBar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.UserCommissionDetails{
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
}
.userCommission-table{
    width: 100%;
    border-collapse: collapse;
}
.Admin-wrap{
    width: 96%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0px auto;
}

.user-details-box{
    padding-top:20px;
    display: flex;
    flex-direction: column;
}
.user-info-box{
    display: flex;
    padding: 2rem;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
}
.user-info-Details-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.user-info-card{
    width: 100%;
    max-width: 300px;
    min-width: 160px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.user-info-label{
    padding: 5px 0px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
}
.user-info-disable-input{
    padding: 10px 0px;
    flex-grow: 1;
    min-width: 160px;
    margin-bottom: 10px;
    max-width: 300px;
    font-size: 16px;
    font-weight: 500;
    color: #333;
}
.user-label{
    padding: 10px 0px;
    font-size: 15px;
    font-weight: 500;
    color: #333;
}
.user-create-card{
    width: 100%;
    max-width: 332px;
    min-width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.user-input-box{
    padding: 10px;
    flex-grow: 1;
    min-width: 160px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 310px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #333;
}
select.user-input-box {
    max-width: 332px;
    color:#757575;
}

.user-info-input-box{
    padding: 10px 0px;
    flex-grow: 1;
    min-width: 160px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 300px;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    border: 0;
    border-bottom: 1px solid #ccc;
    color: #333;
}
.fund-input-box{
    padding: 10px 0px;
    min-width: 160px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 300px;
    outline: none;
    border: 0;
    border-bottom: 1px solid #ccc;
    color: #333;
}
.users-details-card{
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    overflow: auto;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.users-details-table{
    width: 100%;
    border-collapse: collapse;
   
}
.AdminError{
    width: 100%;
    padding:10px 0px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #333;
}
.UsersNavBarMenu{
    display: flex;
    flex-direction: row;
    
}
.NavBarBtnWrap{
    display: flex;
    flex-direction: row;
}
.SearchInput{
    padding: 10px 16px;
    border: 1px solid #ccc;
    outline: none;
    margin: 10px 0px;
    border-radius: 6px;
  }
  .User-Details-wrap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.inputEditbox{
    outline: none;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin: 0px 10px;
    padding: 8px;
  
}

@media only screen and (max-width: 800px){
    .Admin-wrap{
        width: 92%;
    }
    .user-info-box{
        display: flex;
        padding: 2rem 10px;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
    }
    .user-info-card{
        width: 100%;
        max-width: 300px;
        min-width: 160px;
        margin: 0px auto;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .user-info-Details-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
}
@media only screen and (max-width: 600px){
    .UsersNavBarMenu{
        display: flex;
        flex-direction: column;
        
    }
    .user-create-card{
        width: 100%;
        max-width: 312px;
        min-width: 160px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .user-input-box{
        padding: 10px;
        flex-grow: 1;
        min-width: 160px;
        margin-bottom: 10px;
        width: 100%;
        max-width:290px;
        outline: none;
        border: 1px solid #ccc;
        border-radius: 5px;
        color: #333;
    }
    select.user-input-box {
        max-width: 312px;
        color:#757575;
    }
}
body,html{
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    background-color: #fff;
    font-family: 'Roboto', 'Arial', 'Helvetica', 'sans-serif';
}
.container{
    padding-top: 60px;
}
.notificationBar{
    padding:15px 10px;
    margin: 30px 0px 40px 0px;
    text-align: left; 
    border-radius: 5px;
    background-color: #51d9ee;
}
.Recharge-card-box{
    display: none;
    flex-direction: row;
    justify-content: flex-start;
}
 
.Dash-Flex-CardBox-1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Admin-Dash-Flex-CardBox-2{
    display: block;
    width: 100%;
}
.Dash-sub-heading{
    padding: 15px 0px;
    text-align: left;
    font-size: 20px;
    font-weight:500;
    margin-top: 50px;
    
}

.hidden{
    display: none;
    width: 0px;
}

.bg-full-blank{
    left: 0;
    top: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    
    z-index: 1000000;

}



.drawer{
    position: fixed;
    z-index: 10000001;
    height: 100vh;
    background-color: #fff;

    transition: margin 0.2s;
    width: 70%;
    margin: 0 0 0 -70%;
}

.drawer.isOpen{
    margin: 0%;
    box-shadow: 0 0 8px #00000069;

}

.heading{
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    margin: 15px 0px;
}
.dash-report-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    margin:10px;
    border-radius: 10px;
   box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}

.text-success{
    color: #007bff !important;
}

.text-pending{
    color: #ffc107 !important;
}

.text-failure{
    color: #dc3545 !important;
}

.bg-success{
    background-color: #007bff !important;
    color: #fff;
}

.bg-failure{
    background-color: #dc3545 !important;
    color: #fff;
}

.bg-pending{
    background-color: #ffc107 !important;
    color: #333;
}

.bg-info{
    background-color: #17a2b8 !important;
    color: #fff;
}

.Titleheading{
    padding: 20px 30px 10px 30px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;

}
.SubtitleBox{
    padding: 10px 30px 20px 30px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
}
.Dash-recent-transection{
    flex-grow: 1;
    display: flex;
   width: 100%;
    overflow: hidden;
    overflow-x: auto;
    flex-direction:column;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2)
}


.iconButton{
    display: inline-block;
    border-radius: 100%;
    height: 24px;
    width: 24px;
    padding: 24px;
    background-color: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
    cursor: pointer;
}

tr {
    border-bottom: 1px solid #dcdcdc;
}

tr:hover {
    background-color:  #dcdcdc;
}

.Dash-rech-table-view{
    border-collapse: collapse;
}
tr{
    padding: 20px 0px;
}
th{
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border-bottom: 2px solid #dcdcdc;
}
td{
    padding: 15px 20px;
    text-align: center;
}



.AgentsDetailsTilesBox{
    display: flex;
    flex-direction: column;
    padding-top:10px;
    padding-bottom:30px;
    border-radius: 10px;
    width:100%;
    max-width: 300px;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.Agent-profile-box{
    padding-top: 10px;
    text-align: center;
}
.TilesColumns{
    padding: 10px 30px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}
.Pass-btn, .setting-btn{
    width: 160px;
    margin: 10px auto;
    border-radius: 5px;
    padding: 10px 20px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}

@media only screen and (max-width: 800px){
    .Recharge-card-box{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }
    .Dash-Flex-CardBox-1{
        flex-direction: column;
    }
    .dash-report-card{
        margin: 0px 0px 20px 0px;
        max-width: 100%;
    }
    .AgentsDetailsTilesBox{
        margin: 30px 0px;
        max-width: unset;
    }
   
    .notificationBar{
        margin: 30px 0px 30px 0px;
    }
    .Dash-sub-heading{
        margin-top: 30px;
        
    }
}
@media only screen and (max-width: 600px){
    .Recharge-card-box{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
.Commission-heading{
    font-size: 18px;
    font-weight: 600;
    text-align: left;
}
.Commission-table-view{
    border-radius: 10px;
    overflow: hidden;
    overflow-x: auto;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.Commission-table-Box{
   width: 100%;
    border-collapse: collapse;
}
.Complain-card{
    width: 100%;
    display: flex;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
    justify-content: center;
}
.Complain-search-card{
    display: block;
    width: 100%;
    justify-content: center;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
    border-radius: 10px;
}
.complaint-search-form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Complain-form{
    display: flex;
    flex-direction:column;
    justify-content: center;
    flex-wrap: wrap;
    padding:20px;
}
.ComplainInputText{
    width:100%;
    min-width: 160px;
    max-width: 300px;
    border-radius: 5px;
    padding: 10px 16px;
    margin:10px;
    outline: none;
    color:#333;
    border:1px solid #ccc
}
.Complain-table-card{
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    overflow: auto;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.Complain-table{
width: 100%;
  border-collapse: collapse;
}
@media only screen and (max-width: 600px){
    .ComplainInputText{
        width:100%;
        min-width: 160px;
        max-width: 250px;
        border-radius: 5px;
        padding: 10px 16px;
        margin:10px;
        outline: none;
        color:#333;
        border:1px solid #ccc
    }
}

.Payment-request-box{
    margin-top:50px;
}

.content-wrap{
    width: 96%;
    margin: auto;
    display: block;
}

.paddinglr-20{
    padding: 20px;
}
.Payment-request-card{
    display: block;
    width: 100%;
    justify-content: center;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
    border-radius: 10px;

}

.Payment-request-form{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.form-group{
    text-align: start;
    flex-grow: 1;
    min-width: 160px;
    margin: 0px 10px;
    max-width: 332px;
    justify-content: start;
}


.form-control{
    padding: 10px 16px;
    border-radius: 5px;
    flex-grow: 1;
    min-width: 160px;
    margin: 10px;
    width: 100%;
    max-width: 300px;
    outline: none;
    border:1px solid #ccc;
    color:#333;
}




select.form-control {
    max-width: 332px;
}


.Account-detail-box, .Payment-Request-detail-box{
    margin-top: 50px;
}
.Account-detail-card, .Payment-Request-detail-card{
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    overflow: auto;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.Account-detail-table, .Payment-Request-detail-table{
    width: 100%;
    border-collapse: collapse;
   
}

@media only screen and (max-width: 800px){

    .form-control{
        padding: 10px 16px;
        border-radius: 5px;
        flex-grow: 1;
        min-width: 160px;
        margin: 10px;
        width: 100%;
        max-width: unset;
        outline: none;
        border:1px solid #ccc;
        color:#333;
    }
    
    
    select.form-control {
        max-width: unset;
    }
    .content-wrap{
        width:92%;
    }



}
body,html{
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    background-color: #fff;
    font-family: 'Roboto', 'Arial', 'Helvetica', 'sans-serif';
}
.ReportDropdown{
    width:100%;
    display: block;
}
.headerBox{
    width: 100%;
    position: fixed;
    z-index: 9999999;
    background-color: #fff;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,.2);
}
.linkTextDecorate{
    text-decoration: none;
    padding:16.5px;
    color:#000;
    display: block;
}
.linkTextDecorate:hover{
    color:#fff;
    background-color: #680e7a;
}

.text-color-white{
    color: #fff !important;
}
.wrap{
    width: 96%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px auto;
}
.logo{
    font-size: 20px;
    font-weight:bold;
    align-self: center;
    color:#680e7a;
}
.NavBarMenu{
    display: flex;
    flex-direction: row;
   
}
.NavBarMenu ul, .NavBarMenuBtn ul{
    display: inline-flex;
    flex-direction: row;
    margin: 0px;
    padding: 0px;
    list-style: none;
    color:#000;
    
}

.NavBarMenu ul li  {
    cursor: pointer;
}
.NavBarMenuBtn ul li{
    padding: 15px 0px;
}


.Sub-menu-1, .Sub-menu-2, .Sub-menu-3{
    display: none;
}
.NavBarMenu ul li:hover .Sub-menu-1, .NavBarMenu ul li:hover .Sub-menu-2, .NavBarMenu ul li:hover .Sub-menu-3{
    display: block;
    position: absolute;
    box-shadow: 0px 4px 12px 5px rgba(0,0,0,.2);

}
.NavBarMenu ul li:hover .Sub-menu-1 ul, .NavBarMenu ul li:hover .Sub-menu-2 ul, .NavBarMenu ul li:hover .Sub-menu-3 ul{
    display: inline-flex;
    flex-direction:column;
}
.NavBarMenu ul li:hover .Sub-menu-1 ul li, .NavBarMenu ul li:hover .Sub-menu-2 ul li, .NavBarMenu ul li:hover .Sub-menu-3 ul li{
    cursor: pointer; 
    z-index: 9999999;
    background-color: #fff;
    text-align: left;
}
.NavBarMenu ul li:hover .Sub-menu-1 ul li:hover, .NavBarMenu ul li:hover .Sub-menu-2 ul li:hover, .NavBarMenu ul li:hover .Sub-menu-3 ul li:hover{
    background-color: #680e7a;
    color:#fff !important;
}


/* .NavBarMenu ul li:hover .Sub-menu-2{
    display: block;
    position: absolute;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,.2);

}
.NavBarMenu ul li:hover .Sub-menu-2 ul{
    display: inline-flex;
    flex-direction:column;
}
.NavBarMenu ul li:hover .Sub-menu-2 ul li{
    z-index: 9999999;
    background-color: #fff;
    cursor: pointer; 
    text-align: left;
}
.NavBarMenu ul li:hover .Sub-menu-2 ul li:hover{
    background-color: #680e7a;
    color:#fff !important;
} */
/*Header End*/

/* Mobile Header Start*/
.Mobile_HeaderBox{
    display: none;
    width: 100%;
    position: fixed;
    z-index: 9999999;
    background-color: #fff;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,.2);
}
.NavManuBar{
    cursor: pointer;
    padding: 14px 0px;
}
.NavManuBar Div {
    width: 30px;
    height: 4.6px;
    background-color: #680e7a;
}
.Agent-balanceBox{
    padding: 14px 0px;
}
.BackSpaceBtn{
    text-align: left;
    padding:10px 10px;
}
.MobileDropdownMenu{
    display:flex;
    flex-direction: column;
}
.MobileDropdownMenu ul{
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    list-style: none;
    color:#000;
}
.MobileDropdownMenu ul li{
    text-decoration: none;
    cursor: pointer;
    text-align: left;
}
@media only screen and (max-width: 900px){
    .wrap{
        width: 92%;
    }
    .headerBox{
        display: none;
    }
    .Mobile_HeaderBox{
        display: block;
    }
    .logo{
        font-size: 20px;
        font-weight:bold;
        align-self: center;
        margin: auto;
        color:#680e7a;
    }
}

/*Mobile Header End*/


.Radio-group {
    display: flex;
    flex-direction: row;
    text-align: start;
    padding: 20px 0px;
    flex-grow: 1;
    min-width: 160px;
    width: 100%;
    max-width: 332px;
}

.radio-box {
    padding: 10px 0px;
}

.input-form-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
}

.Mob-form-control {
    padding: 10px 16px;
    border-radius: 5px;
    flex-grow: 1;
    min-width: 160px;
    margin: 10px 0px;
    width: 100%;
    max-width: 300px;
    outline: none;
    border: 1px solid #ccc;
    color: #333;
}

select.Mob-form-control {
    max-width: 332px;
}

.rechargeConfirmTile, .confirmTile {
    padding: 5px 0px;
}

.rechargeHeadingTextCol, .dmtHeadingTextCol {
    font-size: 18px;
    font-weight: 500;
}

.confirmWrap {
    padding: 2rem;
}

.mpin {
    border: 1px solid #ddd;
    outline: none;
    margin-top: 2px;
    padding: 10px;
}

.statusCard {
    width: 120px;
    height: 120px;
    border-radius: 120px;
    align-items: center;
    justify-content: center;
    display: flex;
    margin: auto;
}

.statusCard i {
    font-size: 60px;
    color: #fff;
}

@media only screen and (max-width: 600px) {
    .confirmWrap {
        padding: 1rem;
    }
    .mpin {
        display: block;
        width: calc(100% - 20px);
    }
    .rechargeConfirmTile, .rechargeConfirmTile div, .rechargeConfirmTile div button {
        display: block;
        width: 100%;
    }
    .confirmTile, .confirmTile div, .rechargeConfirmTile div button{
        display: block;
        width: 100%;
    }
}

.Topup-history-card{
    width:100%;
    border-radius: 10px;
    overflow: hidden;
    overflow-x: auto;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.topup-history-table{
    width: 100%;
    border-collapse:collapse
}
.trans-search-wrap{
    padding: 15px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    
}
.Rech-status-view-card{
    width: 100%;
    border-radius: 10px;
    margin-top: 30px;
    overflow: hidden;
    overflow: auto;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.trxns-report-card, .Acc-Statement-Card, .Operator-Views-Card{
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    overflow-x: auto;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.trans-report-table, .Acc-statement-table, .Operator-Views-table, .Rech-status-view-table{
    width: 100%;
    border-collapse: collapse;
}
.Count-Details{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:5px 0px;
}
.DateShow{
    padding:10px;
    font-size: 15px;
    font-weight: 500;
    border:1px solid #ccc;
    border-radius: 5px;
    color:#333;
}
.RechargeAmountCount, .AccStatementDetails{
    display: flex;
    flex-direction: row;
    justify-content:flex-end;
}
.RechargeSuccessCommission{
    border:1px solid #ccc;
    border-radius: 5px;
    margin-right: 3px;
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
}
.RechargeSuccessAmount, .ShowBalance{
    border:1px solid #ccc;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    padding:10px;
}
.status-search-wrap{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px 0px;
}

body,html{
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    background-color: #fff;
    font-family: 'Roboto', 'Arial', 'Helvetica', 'sans-serif';
}
.container{
    padding-top: 60px;
}
.notificationBar{
    padding:15px 10px;
    margin: 30px 0px 40px 0px;
    text-align: left; 
    border-radius: 5px;
    background-color: #51d9ee;
}
.Recharge-card-box, .payment_card_box, .mobileViewMode{
    display: none;
}
 
.Dash-Flex-CardBox-1{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Dash-Flex-CardBox-2{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.Dash-sub-heading{
    padding: 15px 0px;
    text-align: left;
    font-size: 20px;
    font-weight:500;
    margin-top: 50px;
    
}

.hidden{
    display: none;
    width: 0px;
}

.bg-full-blank{
    left: 0;
    top: 0;
    position: fixed;
    height: 100%;
    width: 100%;
    
    z-index: 1000000;

}



.drawer{
    position: fixed;
    z-index: 10000001;
    height: 100vh;
    background-color: #fff;

    transition: margin 0.2s;
    width: 70%;
    margin: 0 0 0 -70%;
}

.drawer.isOpen{
    margin: 0%;
    box-shadow: 0 0 8px #00000069;

}

.heading{
    font-size: 20px;
    font-weight: 500;
    text-align: left;
    margin: 15px 0px;
}
.dash-report-card{
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    margin:10px;
    border-radius: 10px;
   box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}

.text-success{
    color: #007bff !important;
}

.text-pending{
    color: #ffc107 !important;
}

.text-failure{
    color: #dc3545 !important;
}

.bg-success{
    background-color: #007bff !important;
    color: #fff;
}

.bg-failure{
    background-color: #dc3545 !important;
    color: #fff;
}

.bg-pending{
    background-color: #ffc107 !important;
    color: #333;
}

.bg-info{
    background-color: #17a2b8 !important;
    color: #fff;
}

.Titleheading{
    padding: 20px 30px 10px 30px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;

}
.SubtitleBox{
    padding: 10px 30px 20px 30px;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
}
.Dash-recent-transection{
    flex-grow: 1;
    display: flex;
    margin-right:40px; 
    overflow: hidden;
    overflow-x: auto;
    flex-direction:column;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2)
}


.iconButton{
    display: inline-block;
    border-radius: 100%;
    height: 24px;
    width: 24px;
    padding: 24px;
    background-color: #fff;
    box-shadow: 0px 0px 6px 0px rgba(0,0,0,0.2);
    cursor: pointer;
}

tr {
    border-bottom: 1px solid #dcdcdc;
}

tr:hover {
    background-color:  #dcdcdc;
}

.Dash-rech-table-view{
    border-collapse: collapse;
}
tr{
    padding: 20px 0px;
}
th{
    padding: 15px 20px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border-bottom: 2px solid #dcdcdc;
}
td{
    padding: 15px 20px;
    text-align: center;
}



.AgentsDetailsTilesBox{
    display: flex;
    flex-direction: column;
    padding-top:10px;
    padding-bottom:30px;
    border-radius: 10px;
    width:100%;
    max-width: 300px;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.Agent-profile-box{
    padding-top: 10px;
    text-align: center;
}
.TilesColumns{
    padding: 10px 30px;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}
.Pass-btn, .setting-btn{
    width: 160px;
    margin: 10px auto;
    border-radius: 5px;
    padding: 10px 20px;
    text-decoration: none;
    text-align: center;
    cursor: pointer;
    outline: none;
    color: #fff;
    border-color: #007bff;
    background-color: #007bff;
}

@media only screen and (max-width: 900px){
    .mobileViewMode{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .notificationBar{
        margin: 30px 0px 30px 0px;
    }
}

@media only screen and (max-width: 800px){
    
    .Dash-Flex-CardBox-1, .Dash-Flex-CardBox-2{
        flex-direction: column;
    }
    .dash-report-card{
        margin: 0px 0px 20px 0px;
        max-width: 100%;
    }
    .AgentsDetailsTilesBox{
        margin: 30px 0px;
        max-width: unset;
    }
    .Dash-recent-transection{
        margin-right: unset;
    }
    
    .Dash-sub-heading{
        margin-top: 30px;
        
    }
}
@media only screen and (max-width: 600px){
    /* .mobileViewMode{
        flex-direction: column;
        justify-content: space-evenly;
    } */
    .Recharge-card-box, .payment_card_box{
        justify-content: space-evenly;
    }
}
.Api_form, .Api_Config_Tab{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.Api_callBackUrlbox, .Api_ipAddressbox, .Api_generateApiKeybox{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.api_callBackUrl_detail, .api_ipAddress_detail, .api_generateApiKey_detail{
    padding: 10px 20px;
}
.Table-Card{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem 0px;
}
.tableRowBox{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.tableColumn{
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 500;
    color: #333;

}
.tableBodyColumn{
    padding: 6px 10px;
    font-size: 14px;
    color: #333;
}

.wallet_create_form, .beneficiary_add_form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.beneficiaryCard, .transaction_card, .aepsTabs_card {
  display: block;
  width: 100%;
  justify-content: center;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
}

.beneficiary_table_card {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  overflow-x: auto;
}

.BeneficiaryBtn {
  padding: 12px 16px;
  border: 0px;
  outline: none;
  cursor: pointer;
  border-radius: 6px;
  background-color: royalblue;
  color: #fff;
}

.BeneficiaryBtn:active {
  outline: none;
}

.amount-control {
  padding: 10px 5px;
  border-radius: 5px;
  width: 100%;
  max-width: 50px;
  outline: none;
  border: 1px solid #ccc;
  color: #333;
}

.mode-control {
  padding: 10px 5px;
  border-radius: 5px;
  width: 80px;
  outline: none;
  border: 1px solid #ccc;
  color: #333;
}
.BeneficiaryDetailsWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.activeTab {
  background-color: lightgrey !important;
}
.utilityDetailsWrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .textHeadingCol {
    font-size: 18px;
    font-weight: 500;
  }
.utility-services{
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    flex-wrap: wrap;
}
.utility-services-form{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.form-group{
    text-align: start;
    flex-grow: 1;
    min-width: 160px;
    margin: 0px 10px;
    max-width: 332px;
    justify-content: start;
}


.form-control{
    padding: 10px 16px;
    border-radius: 5px;
    flex-grow: 1;
    min-width: 160px;
    margin: 10px;
    width: 100%;
    max-width: 300px;
    outline: none;
    border:1px solid #ccc;
    color:#333;
}

@media only screen and (max-width: 800px){

    .form-control{
        padding: 10px 16px;
        border-radius: 5px;
        flex-grow: 1;
        min-width: 160px;
        margin: 10px;
        width: 100%;
        max-width: unset;
        outline: none;
        border:1px solid #ccc;
        color:#333;
    }
    .utility-services{
        
        justify-content:flex-start;
      
    }
    
    select.form-control {
        max-width: unset;
    }
    .content-wrap{
        width:92%;
    }



}
