
.Topup-history-card{
    width:100%;
    border-radius: 10px;
    overflow: hidden;
    overflow-x: auto;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.topup-history-table{
    width: 100%;
    border-collapse:collapse
}
.trans-search-wrap{
    padding: 15px 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    
}
.Rech-status-view-card{
    width: 100%;
    border-radius: 10px;
    margin-top: 30px;
    overflow: hidden;
    overflow: auto;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.trxns-report-card, .Acc-Statement-Card, .Operator-Views-Card{
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    overflow-x: auto;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.trans-report-table, .Acc-statement-table, .Operator-Views-table, .Rech-status-view-table{
    width: 100%;
    border-collapse: collapse;
}
.Count-Details{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding:5px 0px;
}
.DateShow{
    padding:10px;
    font-size: 15px;
    font-weight: 500;
    border:1px solid #ccc;
    border-radius: 5px;
    color:#333;
}
.RechargeAmountCount, .AccStatementDetails{
    display: flex;
    flex-direction: row;
    justify-content:flex-end;
}
.RechargeSuccessCommission{
    border:1px solid #ccc;
    border-radius: 5px;
    margin-right: 3px;
    padding: 10px;
    font-size: 15px;
    font-weight: 500;
}
.RechargeSuccessAmount, .ShowBalance{
    border:1px solid #ccc;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    padding:10px;
}
.status-search-wrap{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px 0px;
}
