.wallet_create_form, .beneficiary_add_form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.beneficiaryCard, .transaction_card, .aepsTabs_card {
  display: block;
  width: 100%;
  justify-content: center;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
}

.beneficiary_table_card {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  overflow-x: auto;
}

.BeneficiaryBtn {
  padding: 12px 16px;
  border: 0px;
  outline: none;
  cursor: pointer;
  border-radius: 6px;
  background-color: royalblue;
  color: #fff;
}

.BeneficiaryBtn:active {
  outline: none;
}

.amount-control {
  padding: 10px 5px;
  border-radius: 5px;
  width: 100%;
  max-width: 50px;
  outline: none;
  border: 1px solid #ccc;
  color: #333;
}

.mode-control {
  padding: 10px 5px;
  border-radius: 5px;
  width: 80px;
  outline: none;
  border: 1px solid #ccc;
  color: #333;
}
.BeneficiaryDetailsWrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.activeTab {
  background-color: lightgrey !important;
}