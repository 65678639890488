.Admin-wrap{
    width: 96%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0px auto;
}

.user-details-box{
    padding-top:20px;
    display: flex;
    flex-direction: column;
}
.user-info-box{
    display: flex;
    padding: 2rem;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
}
.user-info-Details-box{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.user-info-card{
    width: 100%;
    max-width: 300px;
    min-width: 160px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}
.user-info-label{
    padding: 5px 0px;
    font-size: 14px;
    font-weight: 500;
    color: #333;
}
.user-info-disable-input{
    padding: 10px 0px;
    flex-grow: 1;
    min-width: 160px;
    margin-bottom: 10px;
    max-width: 300px;
    font-size: 16px;
    font-weight: 500;
    color: #333;
}
.user-label{
    padding: 10px 0px;
    font-size: 15px;
    font-weight: 500;
    color: #333;
}
.user-create-card{
    width: 100%;
    max-width: 332px;
    min-width: 160px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.user-input-box{
    padding: 10px;
    flex-grow: 1;
    min-width: 160px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 310px;
    outline: none;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #333;
}
select.user-input-box {
    max-width: 332px;
    color:#757575;
}

.user-info-input-box{
    padding: 10px 0px;
    flex-grow: 1;
    min-width: 160px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 300px;
    font-size: 16px;
    font-weight: 500;
    outline: none;
    border: 0;
    border-bottom: 1px solid #ccc;
    color: #333;
}
.fund-input-box{
    padding: 10px 0px;
    min-width: 160px;
    margin-bottom: 10px;
    width: 100%;
    max-width: 300px;
    outline: none;
    border: 0;
    border-bottom: 1px solid #ccc;
    color: #333;
}
.users-details-card{
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    overflow: auto;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.users-details-table{
    width: 100%;
    border-collapse: collapse;
   
}
.AdminError{
    width: 100%;
    padding:10px 0px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #333;
}
.UsersNavBarMenu{
    display: flex;
    flex-direction: row;
    
}
.NavBarBtnWrap{
    display: flex;
    flex-direction: row;
}
.SearchInput{
    padding: 10px 16px;
    border: 1px solid #ccc;
    outline: none;
    margin: 10px 0px;
    border-radius: 6px;
  }
  .User-Details-wrap{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.inputEditbox{
    outline: none;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin: 0px 10px;
    padding: 8px;
  
}

@media only screen and (max-width: 800px){
    .Admin-wrap{
        width: 92%;
    }
    .user-info-box{
        display: flex;
        padding: 2rem 10px;
        flex-direction: column;
        justify-content: center;
        box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2);
    }
    .user-info-card{
        width: 100%;
        max-width: 300px;
        min-width: 160px;
        margin: 0px auto;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .user-info-Details-box{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    
}
@media only screen and (max-width: 600px){
    .UsersNavBarMenu{
        display: flex;
        flex-direction: column;
        
    }
    .user-create-card{
        width: 100%;
        max-width: 312px;
        min-width: 160px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .user-input-box{
        padding: 10px;
        flex-grow: 1;
        min-width: 160px;
        margin-bottom: 10px;
        width: 100%;
        max-width:290px;
        outline: none;
        border: 1px solid #ccc;
        border-radius: 5px;
        color: #333;
    }
    select.user-input-box {
        max-width: 312px;
        color:#757575;
    }
}