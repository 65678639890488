.Commission-heading{
    font-size: 18px;
    font-weight: 600;
    text-align: left;
}
.Commission-table-view{
    border-radius: 10px;
    overflow: hidden;
    overflow-x: auto;
    box-shadow: 0px 2px 4px 2px rgba(0,0,0,0.2);
}
.Commission-table-Box{
   width: 100%;
    border-collapse: collapse;
}