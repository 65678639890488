.Api_form, .Api_Config_Tab{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.Api_callBackUrlbox, .Api_ipAddressbox, .Api_generateApiKeybox{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.api_callBackUrl_detail, .api_ipAddress_detail, .api_generateApiKey_detail{
    padding: 10px 20px;
}
.Table-Card{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem 0px;
}
.tableRowBox{
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}
.tableColumn{
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 500;
    color: #333;

}
.tableBodyColumn{
    padding: 6px 10px;
    font-size: 14px;
    color: #333;
}
