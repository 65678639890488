.packageNameList{
    width: 250px;
    display: flex;
    flex-direction: column;
}
.packageBox {
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    padding: 10px 0px 10px 1rem ;
}
.PackageStatus{
    font-size: 15px;
    font-weight: 500;
    padding: 12px 16px;
    margin: 10px 0px;
}
.PackageDetails{
    width: calc(100% - 250px);
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    border-left: 1px solid #ddd;
}
.packageNavBar{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.UserCommissionDetails{
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
}
.userCommission-table{
    width: 100%;
    border-collapse: collapse;
}