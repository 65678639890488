body,html{
    margin: 0px;
    padding: 0px;
    font-size: 14px;
    background-color: #fff;
    font-family: 'Roboto', 'Arial', 'Helvetica', 'sans-serif';
}
.ReportDropdown{
    width:100%;
    display: block;
}
.headerBox{
    width: 100%;
    position: fixed;
    z-index: 9999999;
    background-color: #fff;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,.2);
}
.linkTextDecorate{
    text-decoration: none;
    padding:16.5px;
    color:#000;
    display: block;
}
.linkTextDecorate:hover{
    color:#fff;
    background-color: #680e7a;
}

.text-color-white{
    color: #fff !important;
}
.wrap{
    width: 96%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0px auto;
}
.logo{
    font-size: 20px;
    font-weight:bold;
    align-self: center;
    color:#680e7a;
}
.NavBarMenu{
    display: flex;
    flex-direction: row;
   
}
.NavBarMenu ul, .NavBarMenuBtn ul{
    display: inline-flex;
    flex-direction: row;
    margin: 0px;
    padding: 0px;
    list-style: none;
    color:#000;
    
}

.NavBarMenu ul li  {
    cursor: pointer;
}
.NavBarMenuBtn ul li{
    padding: 15px 0px;
}


.Sub-menu-1, .Sub-menu-2, .Sub-menu-3{
    display: none;
}
.NavBarMenu ul li:hover .Sub-menu-1, .NavBarMenu ul li:hover .Sub-menu-2, .NavBarMenu ul li:hover .Sub-menu-3{
    display: block;
    position: absolute;
    box-shadow: 0px 4px 12px 5px rgba(0,0,0,.2);

}
.NavBarMenu ul li:hover .Sub-menu-1 ul, .NavBarMenu ul li:hover .Sub-menu-2 ul, .NavBarMenu ul li:hover .Sub-menu-3 ul{
    display: inline-flex;
    flex-direction:column;
}
.NavBarMenu ul li:hover .Sub-menu-1 ul li, .NavBarMenu ul li:hover .Sub-menu-2 ul li, .NavBarMenu ul li:hover .Sub-menu-3 ul li{
    cursor: pointer; 
    z-index: 9999999;
    background-color: #fff;
    text-align: left;
}
.NavBarMenu ul li:hover .Sub-menu-1 ul li:hover, .NavBarMenu ul li:hover .Sub-menu-2 ul li:hover, .NavBarMenu ul li:hover .Sub-menu-3 ul li:hover{
    background-color: #680e7a;
    color:#fff !important;
}


/* .NavBarMenu ul li:hover .Sub-menu-2{
    display: block;
    position: absolute;
    box-shadow: 0px 4px 12px 0px rgba(0,0,0,.2);

}
.NavBarMenu ul li:hover .Sub-menu-2 ul{
    display: inline-flex;
    flex-direction:column;
}
.NavBarMenu ul li:hover .Sub-menu-2 ul li{
    z-index: 9999999;
    background-color: #fff;
    cursor: pointer; 
    text-align: left;
}
.NavBarMenu ul li:hover .Sub-menu-2 ul li:hover{
    background-color: #680e7a;
    color:#fff !important;
} */
/*Header End*/

/* Mobile Header Start*/
.Mobile_HeaderBox{
    display: none;
    width: 100%;
    position: fixed;
    z-index: 9999999;
    background-color: #fff;
    box-shadow: 0px 3px 6px 0px rgba(0,0,0,.2);
}
.NavManuBar{
    cursor: pointer;
    padding: 14px 0px;
}
.NavManuBar Div {
    width: 30px;
    height: 4.6px;
    background-color: #680e7a;
}
.Agent-balanceBox{
    padding: 14px 0px;
}
.BackSpaceBtn{
    text-align: left;
    padding:10px 10px;
}
.MobileDropdownMenu{
    display:flex;
    flex-direction: column;
}
.MobileDropdownMenu ul{
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    list-style: none;
    color:#000;
}
.MobileDropdownMenu ul li{
    text-decoration: none;
    cursor: pointer;
    text-align: left;
}
@media only screen and (max-width: 900px){
    .wrap{
        width: 92%;
    }
    .headerBox{
        display: none;
    }
    .Mobile_HeaderBox{
        display: block;
    }
    .logo{
        font-size: 20px;
        font-weight:bold;
        align-self: center;
        margin: auto;
        color:#680e7a;
    }
}

/*Mobile Header End*/

